import type { AgreementData } from '@/shared/elements/common/payment/types';
import { type PageResponse, PageType } from '@/types';
import { getFirstPage } from '@/utils/session';

type CurrentPage = {
  id: number;
  appId: number;
  flowId: number;
  pollId: number | null;
  title: string;
  order: number;
  status: 1 | 0;
  type: PageType;
  page: string;
  locales: Record<string, any>;
  template: {
    id: number;
    name: PageType;
    title: string;
    batch: null;
    defaultOrder: number;
    status: 1 | 0;
    isMandatory: 1 | 0;
    isStatusChangeable: 1 | 0;
    createDate: string;
    updateDate: string;
  };
};

export type SiteData = {
  path: string;
  pageSlug: string;
  pageOptions: string | null;
  redirectTo?: string;
  bypassRegister?: boolean;
  registerType?: 'email' | 'phoneNumber';
  registerOnPayment?: boolean;
  countryCode?: string;
  phoneNumberAllowedCountries?: string[];
  sessionId: string;
  expireAt: string;
  config: Record<string, any>;
  integrations: {
    gtmData: {
      isActive: 0 | 1;
      gtmCode: string;
    },
    gaData: {
      isActive: 0 | 1;
      gaCode: string;
    };
    facebookData: {
      isActive: 0 | 1;
      pixelId: string;
      integrationType: 'pixel' | 'capi' | 'both';
    }
  };
  availablePages: PageType[];
  page: CurrentPage;
  lang: string;
  preview: PageResponse['result']['preview'];
  isStoreFlow: PageResponse['result']['isStoreFlow'];
  storeHash: PageResponse['result']['storeHash'];
  languageData: PageResponse['result']['languageData'];
  applicationLinks: PageResponse['result']['applicationLinks'];
  social: PageResponse['result']['social'];
  providers: {
    stripe: boolean;
    creditCard: boolean;
    paypal: boolean;
    applePay: boolean;
    googlePay: boolean;
  } | undefined;
  isUserAlreadySubscribed: boolean;
  subscriberId: string;
  isPolicyRequired?: boolean;
  flowImages: string[];
  epinDetails: PageResponse['result']['epinDetails'];
};

export const useSiteStore = defineStore('site', () => {
  const siteData = ref<SiteData>();
  const packages = ref<Record<string, any>[]>([]);
  const agreement = ref<AgreementData>();
  const language = computed(() => siteData.value?.lang);
  const currentPage = computed(() => siteData.value?.page);
  const integrations = computed(() => siteData.value?.integrations || {});
  const providers = computed<SiteData['providers']>(() => siteData.value?.providers || {} as SiteData['providers']);
  const applicationLinks = computed(() => siteData.value?.applicationLinks || {});
  const socialLoginStatus = computed(() => siteData.value?.social || {});
  const availablePages = computed(() => siteData.value?.availablePages || []);
  const config = computed(() => siteData.value?.config || {});
  const isPreview = computed(() => !!siteData.value?.preview);
  const isStoreFlow = computed(() => !!siteData.value?.isStoreFlow);
  const nextPage = computed(() => getNextPage());
  const countryCode = computed(() => siteData.value?.countryCode);
  const epinDetails = computed(() => siteData.value?.epinDetails || {});

  function setSiteData(payload: SiteData) {
    siteData.value = payload;
  }

  function setPackages(packageList: Record<string, any>[]) {
    packages.value = packageList;
  }

  function setAgreement(agreementObj: Record<string, any>) {
    agreement.value = agreementObj;
  }

  function getNextPage() {
    const pageType = currentPage.value?.template?.name || getFirstPage();
    const currentPageIndex = availablePages.value?.indexOf(pageType);
    const availablePagesCount = availablePages.value?.length;
    const nextPageIndex = (currentPageIndex + 1) % availablePagesCount;
    let newPage = availablePages.value[nextPageIndex];

    if (newPage === PageType.REGISTER && siteData.value?.bypassRegister) {
      newPage = availablePages.value[(currentPageIndex + 2) % availablePagesCount];
    }

    return newPage;
  }

  return {
    packages,
    agreement,
    siteData,
    currentPage,
    availablePages,
    integrations,
    providers,
    config,
    language,
    isPreview,
    isStoreFlow,
    nextPage,
    applicationLinks,
    socialLoginStatus,
    countryCode,
    epinDetails,
    setSiteData,
    setPackages,
    setAgreement,
    getNextPage
  };
});
