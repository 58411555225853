import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import clickOutside_ZB3Eg0wOVk from "/opt/atlassian/pipelines/agent/build/plugins/clickOutside.ts";
import vee_validate_client_P40JVl0wNI from "/opt/atlassian/pipelines/agent/build/plugins/vee-validate.client.ts";
import vue_toastification_client_F29MfCWaUJ from "/opt/atlassian/pipelines/agent/build/plugins/vue-toastification.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  clickOutside_ZB3Eg0wOVk,
  vee_validate_client_P40JVl0wNI,
  vue_toastification_client_F29MfCWaUJ
]