<template>
  <svg
    version="1.1"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :aria-labelledby="name"
    :width="getSize"
    :height="getSize"
    :data-test="name"
  >
    <component :is="currentIcon" />
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Icons from './icons';

const props = defineProps({
  name: {
    type: String,
    default: 'Danger'
  },
  size: {
    type: [Number, String],
    default: 'sm'
  }
});

const currentIcon = computed(() => Icons[props.name]);

const getSize = computed(() => {
  switch (props.size) {
    case 'xs':
      return '16';
    case 'sm':
      return '24';
    case 'md':
      return '32';
    case 'lg':
      return '48';
    case 'xl':
      return '64';

    default:
      return props.size;
  }
});
</script>
