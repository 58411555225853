<template>
  <button
    class="tw-btn"
    :type="type"
    :class="btnClass"
    :disabled="disabled || loading"
  >
    <WebSpinner
      v-if="loading && isSpinnerTextSet"
      data-test="spinner"
      :direction="spinnerDirection"
      :type="spinnerType"
      :text="spinnerText"
      :size="spinnerSize"
      class="animate-fadeIn"
    />
    <template v-else>
      <WebSpinner
        v-if="loading"
        data-test="spinner"
        :type="spinnerType"
        :size="spinnerSize"
        background="currentColor"
        class="mr-8 animate-fadeIn"
      />

      <WebIcon
        v-if="leftIcon && !loading"
        data-test="left-icon"
        class="mr-8"
        :name="leftIcon"
        :size="iconSize"
      />
      <slot />
      <WebIcon
        v-if="rightIcon"
        data-test="right-icon"
        class="ml-8"
        :name="rightIcon"
        :size="iconSize"
      />
    </template>
  </button>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import WebSpinner from '@shared/components/spinner/index.vue'
import WebIcon from '@shared/components/icon/index.vue';

const props = defineProps({
  type: { type: String as PropType<'button' | 'submit' | 'reset'>, default: undefined },
  full: { type: Boolean, default: false },
  size: { type: String, default: 'lg' },
  variant: { type: String, default: 'primary' },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  leftIcon: { type: String, default: '' },
  rightIcon: { type: String, default: '' },
  spinnerType: { type: String, default: '' },
  spinnerDirection: { type: String, default: 'left' },
  spinnerText: { type: String, default: '' },
  to: { type: Object, default: () => ({}) },
  isSpinnerTextSet: { type: Boolean, default: false }
});

const btnClass = computed(() => {
  return {
    [`tw-btn-${props.size}`]: !!props.size,
    [`tw-btn-${props.variant}`]: !!props.variant,
    'tw-btn-full': props.full
  };
});

const iconSize = computed(() => {
  if (props.size === 'lg') return '20';
  else return '16';
});

const spinnerSize = computed(() => {
  if (props.size === 'sm') return '12';
  else if (props.size === 'md') return '16';
  else return '20';
});
</script>

<style lang="postcss" scoped>
.tw-btn {
  @apply inline-flex items-center justify-center rounded-8 px-24 font-medium transition-shadow focus:ring-0 focus-visible:outline-none disabled:cursor-not-allowed;

  &-primary {
    @apply bg-primary-500 text-white hover:bg-primary-700 focus:shadow-primary;
  }

  &-secondary {
    @apply bg-primary-100 text-primary-500 hover:bg-primary-200 focus:shadow-secondary;
  }

  &-primary,
  &-secondary {
    @apply disabled:bg-primary-100 disabled:text-primary-200;
  }

  &-error {
    @apply bg-error-500 text-white hover:bg-error-700 focus:shadow-error disabled:bg-error-100 disabled:text-error-200;
  }

  &-outline {
    @apply bg-white text-neutral-900 shadow-[inset_0_0_0_1px_#E7EAEE] hover:bg-neutral-100 focus:bg-white focus:shadow-[inset_0_0_0_1px_#E7EAEE,0_0_0_4px_#F6F7F9] disabled:bg-white disabled:text-neutral-300;
  }

  &-text {
    @apply text-secondary-500 hover:underline hover:underline-offset-2 disabled:text-secondary-200 disabled:no-underline;
  }

  &-full {
    @apply w-full;
  }

  &-lg {
    @apply min-h-[48px] py-12 text-16;
  }
  &-md {
    @apply min-h-[40px] py-8 text-14;
  }
  &-sm {
    @apply min-h-[32px] py-[6px] text-12;
  }
}
</style>
