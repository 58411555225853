import type { Directive } from 'vue';

const TAG = '__v-click_outside';
const HAS_WINDOWS = typeof window !== 'undefined';
const HAS_NAVIGATOR = typeof navigator !== 'undefined';
const IS_TOUCH = HAS_WINDOWS && ('ontouchstart' in window || (HAS_NAVIGATOR && navigator.maxTouchPoints > 0));
const EVENTS = IS_TOUCH ? 'touchstart' : 'click';

function onEvent({ el, event, handler }: { el: HTMLElement; event: PointerEvent; handler(evt: PointerEvent): void }) {
  const path = event.composedPath && event.composedPath();
  const isClickOutside = path ? path.indexOf(el) < 0 : !el.contains(event.target as any);

  if (!isClickOutside) {
    return;
  }

  handler(event);
}

const clickOutside: Directive = {
  mounted(el, { value }) {
    el[TAG] = {
      handler: (event: PointerEvent) => onEvent({ el, event, handler: value })
    };

    window.addEventListener(EVENTS, el[TAG].handler);
  },

  unmounted(el) {
    window.removeEventListener(EVENTS, el[TAG].handler);
  }
};

export default clickOutside;
