import { defineAsyncComponent } from 'vue';

function getAllIcons() {
  const files = import.meta.glob('./*.vue');
  return Object.entries(files).reduce((acc: Record<string, any>, [fileName, file]) => {
    const name = fileName.replace(/.+\/(.*?)\.vue/g, '$1');
    acc[name] = defineAsyncComponent(() => (file as any)());
    return acc;
  }, {});
}

export default getAllIcons();
