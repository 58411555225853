<template>
  <NuxtPage />
  <CookiePopUp />
</template>

<script lang="ts" setup>
import { useSiteStore } from '@/stores/site';
import CookiePopUp from './components/cookie/index.vue';

const { siteData } = useSiteStore()

if (import.meta.client) {
  registerCacheStorage(siteData?.flowImages || [])
}
</script>

<style lang="postcss">
body {
  background-color: var(--app-backgroundColor, '#FFFFFF');
  background-image: var(--app-backgroundImage-mobile);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-color 0.15s linear;

  @media (min-width: 768px) {
    background-image: var(--app-backgroundImage-tablet);
  }

  @media (min-width: 1024px) {
    background-image: var(--app-backgroundImage-desktop);
  }
}

#__nuxt {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @supports (min-height: 100svh) {
    min-height: 100svh;
  }

  .tw-section.tw-section-footer,
  .tw-section.tw-zotlofooter {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
</style>
