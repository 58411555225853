export function parseQueryString(query: string) {
  const [ qStr ] = (query ? (query.charAt(0) === '?' ? query.slice(1) : query) : '').split('#');

  return qStr.split('&').reduce((acc, str) => {
    const [ key, value ] = str.split('=');

    if (key && !Object.prototype.hasOwnProperty.call(acc, key)) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);
}
