import { PageType } from '@/types';
import { useSiteStore } from '@/stores/site';
import { getBaseUrl } from '@/utils/getBaseUrl';
import { canViewable, getFirstPage } from '@/utils/session';
import { parseQueryString } from '@/utils/queryString';
import { handleUserAlreadySubscribedError } from '@/utils/handleUserAlreadySubscribed';
import { getNamedCookie, COOKIE } from '@/utils/cookie';

function triggerRouteChange(routeName: string) {
  if (import.meta.client) {
    window.parent.postMessage({ route: routeName }, '*');
  }
}

function prepareQueryString(query: string, hash?: string) {
  // Add UTM info as query string if there is
  const utmObj = JSON.parse(getNamedCookie(COOKIE.UTM) || '{}')
  const queryObj = parseQueryString(query);
  const fullList = {...utmObj, ...queryObj };
  const uniqList = Object.entries(fullList).reduce((acc, [key, value]) => {
    if (key && !Object.prototype.hasOwnProperty.call(acc, key)) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);

  const queryString = Object.entries(uniqList).reduce((acc, [key, value]) => {
    acc.push(`${key}=${value}`)
    return acc;
  }, [] as string[]).join('&').split('#')[0];

  return (queryString ? `?${queryString}` : '') + (hash ? `#${hash}` : '') ;
}

export default defineNuxtRouteMiddleware(async (to) => {
  const { currentPage, isPreview, siteData } = useSiteStore(usePinia());
  const { pageType: page, slug, lang, query, hash } = getBaseUrl(to.fullPath);
  const pathName = (to.name === 'slug' ? page || getFirstPage() : to.name) as PageType;
  const queryString = prepareQueryString(query, hash);
  const availablePages = siteData?.availablePages || JSON.parse(getNamedCookie(COOKIE.AVAILABLE_PAGES) || '[]') as string[];
  const isUserAlreadySubscribed = siteData?.isUserAlreadySubscribed
  const bypassRegister = !!siteData?.bypassRegister;
  const firstPage = getFirstPage();
  const is404 = to.name === '404';
  const [path] = to.fullPath.split('?');
  const isApiPath = /^api\/?/g.test(to.fullPath);
  const isFileExt = /\.(.*?)$/g.test(path);

  if (is404) return;

  if (isFileExt) {
    // Check if route has any file extension or be sure that url is not
    triggerRouteChange('404');
    return navigateTo('/404');
  }

  if (siteData?.redirectTo) {
    return navigateTo(siteData?.redirectTo, { external: true });
  }

  if (isUserAlreadySubscribed) {
    return handleUserAlreadySubscribedError(siteData?.subscriberId, slug)
  }

  if (!currentPage || !availablePages.includes(pathName)) {
    if (!isApiPath) {
      triggerRouteChange('404');
      return navigateTo('/404');
    }
  }

  if (!canViewable(pathName)) {
    return navigateTo('/'+ [slug, lang, firstPage].filter(Boolean).join('/') + queryString);
  }

  if (pathName === PageType.PAYMENT_SUCCESS && !isPreview && !isUserAlreadySubscribed) {
    // If subscriberId is not in the cookies, payment success page will be redirected to payment
    const subscriberId = useCookie(COOKIE.SUBSCRIBER_ID, { sameSite: 'none', secure: true, path: `/${slug}` });
    if (!subscriberId.value) {
      const paymentPageUrl = '/' + [slug, lang, PageType.PAYMENT].filter(Boolean).join('/');
      return navigateTo(paymentPageUrl + queryString);
    }
  }

  const isFirstPage = pathName === firstPage;
  const pagePath = !isFirstPage || (isFirstPage && bypassRegister && [PageType.PAYMENT, PageType.PAYWALL].includes(firstPage)) ? pathName : '';
  const redirectTo = '/' + [slug, lang, pagePath].filter(Boolean).join('/') + queryString;

  triggerRouteChange(pathName);

  if (redirectTo !== to.fullPath) {
    return navigateTo(redirectTo);
  }
});
