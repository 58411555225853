import { PageType } from '@/types';
import { setNamedCookie, COOKIE } from './cookie';
import { goToPage } from './getBaseUrl'

export async function handleUserAlreadySubscribedError(subscriberId:string, cookiePath: string) {
  const subscriberIdValue = (subscriberId && subscriberId.charAt(0) === '+') ? subscriberId.substring(1) : subscriberId;
  setNamedCookie({
    cookieName: COOKIE.USER_ALREADY_SUBSCRIBED,
    value: 'true',
    path: cookiePath
  });
  setNamedCookie({
    cookieName: COOKIE.SUBSCRIBER_ID,
    value: subscriberIdValue,
    path: cookiePath
  });
  return goToPage(PageType.PAYMENT_SUCCESS, { excludeQueryParams: ['provider', 'code', COOKIE.SUBSCRIBER_ID] });
}
