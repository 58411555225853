import { v4 as uuidv4 } from 'uuid';
import { PageType } from "@/types";
import { getNamedCookie, setNamedCookie, COOKIE, getTimeAsUTC, generateExpireTime } from './cookie';
import { useSiteStore } from '@/stores/site';
import { getBaseUrl } from './getBaseUrl';

export function getVisitedPages() {
  return JSON.parse(getNamedCookie(COOKIE.VISITED) || '[]');
}

export function setVisitedPages(pageType: PageType) {
  const isPreview = !!getNamedCookie(COOKIE.IS_PREVIEW);
  if (!canViewable(pageType) || isPreview) return;

  const visitedPages = getVisitedPages();
  const route = useRoute();
  const { slug } = getBaseUrl(route?.fullPath);

  if (!visitedPages.includes(pageType)) {
    visitedPages.push(pageType);
    const { siteData } = useSiteStore()
    const expireAt = siteData?.expireAt || getNamedCookie(COOKIE.EXPIRE_AT);
    const date = new Date(getTimeAsUTC(expireAt || ''));
    setNamedCookie({
      cookieName: COOKIE.VISITED,
      value: JSON.stringify(visitedPages),
      exdays: date,
      path: slug
    });
  }
}

export function canViewable(pageType: PageType) {
  const { siteData } = useSiteStore();
  const availablePages = siteData?.availablePages || JSON.parse(getNamedCookie(COOKIE.AVAILABLE_PAGES) || '[]') as string[];
  const isPreview = !!siteData?.preview || !!getNamedCookie(COOKIE.IS_PREVIEW);

  if (isPreview) return true;

  const visitedPages = getVisitedPages();
  const bypassRegister = !!siteData?.bypassRegister || !!getNamedCookie(COOKIE.BYPASS_REGISTER);
  const isUserAlreadySubscribed = !!getNamedCookie(COOKIE.USER_ALREADY_SUBSCRIBED);

  function isPageAvailable(page: PageType) {
    return availablePages.includes(page);
  }

  function isPageVisited(page: PageType) {
    return visitedPages.includes(page);
  }

  switch (pageType) {
    case PageType.LANDING:
      return availablePages.includes(PageType.LANDING);
    case PageType.QUIZ: {
      const isAvailableQuiz = isPageAvailable(PageType.QUIZ);
      const isAvailableLanding = isPageAvailable(PageType.LANDING);

      if (isAvailableQuiz && isAvailableLanding) {
        return isPageVisited(PageType.LANDING);
      }
      return isAvailableQuiz;
    }
    case PageType.REGISTER: {
      if (bypassRegister) return false;

      const isAvailableRegister = isPageAvailable(PageType.REGISTER);
      if (isAvailableRegister) {
        if (isPageAvailable(PageType.QUIZ)) {
          return isPageVisited(PageType.QUIZ);
        } else if (isPageAvailable(PageType.LANDING)) {
          return isPageVisited(PageType.LANDING);
        }
      }

      return isAvailableRegister;
    }
    case PageType.PAYWALL: {
      const isAvailablePaywall = isPageAvailable(PageType.PAYWALL);
      if (!isAvailablePaywall) return false;

      if (!bypassRegister && isPageAvailable(PageType.REGISTER)) {
        return isPageVisited(PageType.REGISTER);
      } else if (isPageAvailable(PageType.QUIZ)) {
        return isPageVisited(PageType.QUIZ);
      } else if (isPageAvailable(PageType.LANDING)) {
        return isPageVisited(PageType.LANDING);
      }

      return true;
    }
    case PageType.PAYMENT: {
      const isAvailablePayment = isPageAvailable(PageType.PAYMENT);
      if (!isAvailablePayment) return false;

      if (isPageAvailable(PageType.PAYWALL)) {
        return isPageVisited(PageType.PAYWALL);
      } else if (!bypassRegister && isPageAvailable(PageType.REGISTER)) {
        return isPageVisited(PageType.REGISTER);
      } else if (isPageAvailable(PageType.QUIZ)) {
        return isPageVisited(PageType.QUIZ);
      } else if (isPageAvailable(PageType.LANDING)) {
        return isPageVisited(PageType.LANDING);
      }

      return true;
    }
    case PageType.PAYMENT_SUCCESS: {
      if (isUserAlreadySubscribed) return true;
      return isPageVisited(PageType.PAYMENT);
    }
  }

  return true;
}

export function getFirstPage() {
  const { siteData } = useSiteStore();
  const bypassRegister = !!siteData?.bypassRegister || !!getNamedCookie(COOKIE.BYPASS_REGISTER);
  const availablePages = siteData?.availablePages || JSON.parse(getNamedCookie(COOKIE.AVAILABLE_PAGES) || '[]') as string[];
  let page = availablePages[0] as PageType || PageType.LANDING;

  if (page === PageType.REGISTER && bypassRegister) {
    page = availablePages[1] as PageType;
  }

  return page;
}

export function isSessionExpired(customExpireAt?: string) {
  const { siteData } = useSiteStore()
  const expireAt = customExpireAt || siteData?.expireAt || getNamedCookie(COOKIE.EXPIRE_AT);
  const isExpired = getTimeAsUTC() > getTimeAsUTC(expireAt || '');
  if (isExpired) {
    const route = useRoute();
    const { slug } = getBaseUrl(route?.fullPath);
    reloadSessionId(undefined, slug);
    return isExpired;
  }
  return false;
}

export function checkCurrentSession() {
  if (isSessionExpired()) {
    globalThis?.location?.reload();
    return true;
  }

  return false;
}

export function reloadSessionId(isPreview?: boolean, path?: string) {
  deleteNamedCookie({ name: COOKIE.VISITED, path });
  setNamedCookie({
    cookieName: COOKIE.SESSION_ID,
    value: `${uuidv4()}${isPreview ? ';SameSite=None; Secure' : ''}`,
    exdays: generateExpireTime().date,
    path
  });
}
