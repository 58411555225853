import { defineRule, configure } from 'vee-validate';
import * as AllRules from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import { getCardMask, getCountryCodeByNumber, getCountryByCode } from '@shared/components/input/utils';
import { useSiteStore } from '@/stores/site';

function updateValidationMessages(payload: { locale: string, messages: Record<string, any> }) {
  const localized = Object.entries(payload.messages).reduce((acc: Record<string, any>, [locale, messages]) => {
    const message = messages?.generate?.form?.validation?.rule;
    if (message) acc[locale] = { messages: JSON.parse(JSON.stringify(message)) };
    return acc;
  }, {});

  configure({
    generateMessage: localize(localized)
  });

  setLocale(payload.locale);
}

export default defineNuxtPlugin(async (app) => {
  const { siteData } = useSiteStore(usePinia())
  const { lang } = siteData || { lang: 'en', locales: {} }
  const messages = (app?.$i18n as any)?.messages?.value || {}; 
  const locales = messages[lang];

  Object.keys(AllRules.all).forEach((rule) => {
    defineRule(rule, (AllRules as any)[rule]);
  });

  defineRule('expirationDate', (value: string) => {
    const pattern = /^(0[1-9]|1[0-2])[0-9]{2}$/;
    return pattern.test(value);
  });

  defineRule('card', (value: string) => {
    const card = getCardMask(value);
    return value.length === card.length;
  });

  defineRule('phoneFormat', (value: string, countryCode:string[]) => {
    const code = countryCode?.[0] || getCountryCodeByNumber(value, false);
    const country = getCountryByCode(code);
    const maxLength = country?.maxMaskLength || 16;
    const minLength = country?.minMaskLength || 7;

    if (value.length > maxLength || value.length < minLength) {
      return 'Invalid phone number format';
    }

    return true;
  });

  updateValidationMessages({ locale: lang, messages: { [lang]: locales } })
});

