import { useSiteStore } from "@/stores/site";
import { showToast } from "@/utils/toast";

export const api = {
  getBaseUrl() {
    const runtimeConfig = useRuntimeConfig()
    const baseUrl = runtimeConfig.public.NUXT_API_URL as string || '';
    return baseUrl;
  },

  handleShowErrorToast(meta: Record<string, any>, newHeaders:Record<string, any> ) {
    const {
      hideErrorToast = false,
      hideErrorToastFor = []
    } = newHeaders || {};
    const errorCode = meta?.errorCode;
    const errorMessage = meta?.message;

    if (errorCode && !hideErrorToast && !hideErrorToastFor?.includes(errorCode)) {
      showToast(errorMessage, '', 'error');
    }
  },

  async prepareResponse(response: Response, newHeaders:Record<string, any>) {
    const contentType = response.headers.get("Content-Type") as string;
    const data = /json/.test(contentType)
      ? await response.json()
      : await response.text();

    const { meta } = data;
    this.handleShowErrorToast(meta, newHeaders);

    return {
      status: response.status,
      statusText: response.statusText,
      data
    }
  },

  async request(options: {
    url: string;
    method?: string;
    headers?: Record<string, string>;
    body?: Record<string, string> | FormData;
  }) {
    const { url, method, headers, body  } = options;
    const slug = ""; // this.getSlug();
    const baseUrl = this.getBaseUrl();
    const requestUrl = /https?:\/\//.test(url) ? url : `${baseUrl}${slug}${url}`;
    const isFormData = body instanceof FormData;

    const newHeaders = {
      'Content-Type': 'application/json',
      ...headers,
    }

    if (isFormData && !headers?.['Content-Type'] && newHeaders['Content-Type']) {
      delete (newHeaders as any)['Content-Type'];
    }

    if (import.meta.client) {
      const { language, siteData } = useSiteStore();
      if (!Object.prototype.hasOwnProperty.call(newHeaders, 'Language')) {
        (newHeaders as any)['Language'] = language
      }
      if (!Object.prototype.hasOwnProperty.call(newHeaders, 'Uuid')) {
        (newHeaders as any)['Uuid'] = siteData?.sessionId
      }
    }

    return fetch(requestUrl, {
      method: (method as any) || "GET",
      headers: newHeaders,
      body: body
        ? body instanceof FormData ? body : JSON.stringify(body)
        : undefined,
    }).then((response) => this.prepareResponse(response, newHeaders));
  },

  get(url: string, headers?: Record<string, any>) {
    return this.request({ url, headers });
  },

  post(url: string, data?: Record<any, any> | FormData, headers?: Record<string, any>) {
    return this.request({ url, method: "POST", body: data, headers });
  },

  put(url: string, data?: Record<any, any> | FormData, headers?: Record<string, any>) {
    return this.request({ url, method: "PUT", body: data, headers });
  },

  delete(url: string, data?: Record<any, any>, headers?: Record<string, any>) {
    return this.request({ url, method: "DELETE", body: data, headers });
  }
}

export default api;
